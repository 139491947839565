
@font-face {
  font-family: 'Galindo-Regular';
  src: local('Galindo-Regular'),
    url('./fonts/Galindo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('./fonts/Montserrat.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Black';
  src: local('Montserrat-Black'),
    url('./fonts/Montserrat-Black.ttf') format('truetype');
}

html{
  background: linear-gradient(#283452, #5d77bd);
  width: 100%;
  min-width: 360px;
  margin-left: auto;
  margin-right: auto;

}

.header, .roadmap, .tokenomics, .prefooter-conteiner, .footer, .airdrop{
  position: relative;
  z-index: 1;
}

.noise{
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

*{
  margin: 0;
  padding: 0;
}

.wrapper{
  width: 86%;
  margin-left: auto;
  margin-right: auto;
}

.wrapper_{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

ul{
  list-style-type:  none;  
}
/*header*/

header{
  display: flex;
  background-image: url(./assets/images/background.svg);
  background-repeat: no-repeat;
  background-size: cover;

}


.header-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header-container span{
  font-weight: 700;
}

.header-container-title{
  width: 75%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.header-container-title img{
  width: 762px;
  margin-left: auto;
  margin-right: auto;
  transform:translate(0%, 43%);
  padding-left: 6%;
}

.header-container-content{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.dogwifcash, .dog{
  display: flex;
}

.dogwifcash{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.dogwifcash{
  flex-direction: column;
}

.dogwifcash h1{
  font-size: 1.7vw;
  padding-bottom: 1.5vw;
  color: #212121;
  font-family: 'Montserrat';
  font-weight: 400;
}

.copy h2{
  font-size: 1.2vw;
  color: #FAFAFA;
  font-family: 'Montserrat';
  font-weight: 400;
  padding-top: 3vw;
}

.copy{
  display: flex;
}

.copy input{
  font-size: 1.2vw;
  color: #FAFAFA;
  font-family: 'Montserrat';
  font-weight: 400;
  padding-left: 0.3vw;
  padding-top: 3vw;
  background-color: transparent;
  outline:none;
  border: none;
  width: 32vw;
  padding-bottom: 3vw;
}

.copy button{
  background-color: transparent;
  border: none;
  width: 3vw;
  transform: translate(0px, 0.5vw);;
}


.copy img{
  cursor: pointer;
  width: 4vw;
  padding-left: 1vw;
}

.copy img:hover{
  cursor: pointer;
  width: 4.1vw;
}

.button-conteiner-buy{
  padding-top: 1.5vw;
}

img.dog-left{
  z-index: -1;
  width: 32.4vw;
  padding-top: 6vw;
  padding-bottom: 1vw;
  padding-left: 12vw;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  filter: drop-shadow(0 -3vw 8vw #361ea4);
}

.twenty-conteiner{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}


img.dog-right{
  position: relative;
  width: 38vw;
  padding-top: 7.2vw;
  padding-bottom: 1vw;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  
}

.social{
  display: flex;
  flex-direction: column;
}

.social-conteiner{
  display: flex;
}

.social-conteiner h4{
  color: #fff;
  font-size: 2vw;
  font-family: 'Montserrat';
  font-weight: 400;
  padding-top: 3vw;
  padding-bottom: 4vw;
  padding-left: 13vw;
}

.button-conteiner{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 68%;
  justify-content: space-between;
  padding-left: 12vw;
}

.button  img{
  width: 14.896vw;
}

.wallet-conteiner{
  display: flex;
}

.wallet{
 flex-direction: column;
}

.wallet h2{
  color: #FAFAFA;
  font-family: 'Montserrat';
  padding-bottom: 1vw;
  font-size: 1.6vw;
  font-weight: 400;
  padding-left: 13.5vw;
  padding-top: 5vw;
  text-transform: uppercase;
}

.wallet h3{
  color: #FAFAFA;
  font-family: 'Montserrat';
  padding-bottom: 2vw;
  font-size: 1.6vw;
  font-weight: 400;
  padding-left: 13.5vw;
  text-transform: uppercase;
}

.wallet span{
  font-family: 'Montserrat-Black';
}

.wallet-transaction{
  display: flex;
  padding: 0.5em 0;
}

.wallet-transaction h3{
  color: #fff;
  font-size: 14.22px;
  padding-top: 0.5vw;
}

.you-pay{
  padding-right: 0.2em;
}

.wallet-transaction input{
  margin: 0 1em;
  padding: 0.5em 0;
}

.social ul{
  display: flex;
  padding-left: 2vw;
  padding-top: 3vw;
}

.social img{
  width: 2.865vw;
}

.social-last-item{
  padding-left: 1.5vw;
}

.social p{
  font-family: 'Choko';
  padding-top: 1.3vw;
  font-size: 1.8vw;
  font-weight: 700;
  color: #212121;
}



.content-cont-dog{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 3vw;
}

.content-img-dog img{
  width: 37.50vw;
  filter: drop-shadow(0 -3vw 8vw #F6F6F6);
}

.content-dog p{
  color: #FAFAFA;
  font-family: 'Montserrat';
  padding-bottom: 2vw;
  font-size: 1.7vw;
  font-weight: 400;
  padding-top: 2vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
}

.content-dog span{
  font-family: 'Montserrat-Black';
}


.tokenomics-container {
  padding-top: 10vw;
  position: relative;
}

.tokenomics-container img{
  position: relative;
  z-index: 0;
  width: 86.5vw;
  padding-bottom: 2vw;
  filter: drop-shadow(0 -4vw 8vw #F6F6F6);
}

.tokenomics-container-content h2{
  font-family: 'Choko';
  padding-top: 1.3vw;
  font-size: 6.771vw;
  font-weight: 400;
  color: #79BBE5;
  position: absolute;
  top: 14vw;
  left: 27vw;
}

.tokenomics-content{
  color: #fafafa;
  font-family: 'Montserrat';
  padding-bottom: 2vw;
  font-size: 1.7vw;
  font-weight: 600;
  position: absolute;
  top: 27.5vw;
  left: 1.5vw;
  text-transform: uppercase;
  filter: drop-shadow(0 0.05vw 0.06vw #004262);
}

.tokenomics-content li{
  padding-bottom: 1vw;
}



.roadmap-container{
  width: 100%;
}

.roadmap-container img{
  display: flex;
  width: 60.156vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15vw;
  filter: drop-shadow(0 -4vw 8vw #F6F6F6);
}


.roadmap-container-content{
  width: 100%;
  position: relative;
}

.roadmap-container-content h2{
  text-align: center;
  font-family: 'Galindo-Regular';
  padding-top: 1.3vw;
  font-size: 6.771vw;
  font-weight: 400;
  color: #FAFAFA;
  padding-top: 4vw;
}

.roadmap-content-item-one, .roadmap-content-item-two, .roadmap-content-item-three, .roadmap-content-item-fore, .roadmap-content-item-five{
  position: absolute;
  color: #fafafa;
  font-family: 'Montserrat';
  font-size: 1.667vw;
  font-weight: 400;
}

.roadmap-content-item-one p, .roadmap-content-item-two p, .roadmap-content-item-three p, .roadmap-content-item-fore p, .roadmap-content-item-five p{
  padding-top: 0vw;
}

.roadmap-content span{
  font-family: 'Galindo-Regular';
  font-size: 3vw;
  font-weight: 400;
  color: #fafafa;
}

.roadmap-content-item-one{
  top: 18vw;
  left: 7.5vw;
}

.roadmap-content-item-two{
  top: 30vw;
  right: 0vw;
  text-align: right;
}

.roadmap-content-item-three{
  top: 43vw;
  left: 36vw;
}

.roadmap-content-item-fore{
  top: 82vw;
  left: 4.5vw;
}

.roadmap-content-item-five{
  top: 75vw;
  right: 1vw;
}


.airdrop-container-content, .airdrop-content{
  display: flex;
  width: 100%;
}

.airdrop-content img{
  margin-left: auto;
  display: flex;
  z-index: 0;
  width: 34.64vw;
  padding-top: 15vw;
  filter: drop-shadow(0 -4vw 8vw #F6F6F6);
}

.airdrop-content-item{
  margin-right: auto;
  padding-top: 16vw;
}

.airdrop-content-item p{
  font-size: 1.875vw;
  color: #fafafa;
  font-family: 'Montserrat';
  padding-bottom: 2vw;
  font-size: 1.7vw;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 2.5vw;
  letter-spacing: 0.12vw;
}

.airdrop-content-item .content-color{
  font-family: 'Galindo-Regular';
  padding-top: 1.3vw;
  font-size: 4.3vw;
  font-weight: 400;
  color: #fafafa;
  text-transform: uppercase;
  line-height: 5vw;
}


.airdrop-content-item span{
  font-family: 'Montserrat-Black';
}

.prefooter{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


.prefooter p{
  text-align: center;
  text-transform: uppercase;
  font-family: 'Galindo-Regular';
  font-size: 1.2vw;
  color: #fafafa;
  padding-top: 9vw;
}

.footer{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


.footer p{
  text-align: center;
  text-transform: uppercase;
  font-family: 'Galindo-Regular';
  font-size: 1.5vw;
  color: #DE6A6A;
  padding-top: 4vw;
  padding-bottom: 6vw;
}

.social_link{
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  padding-top: 3vw;
}

.social_link img{
  width: 3vw;
  height: 3vw;
}


@media  (max-width: 600px){
  .social img{
    width: 4.5vw;
  }

  .copy h2{
    padding-top: 3.5vw;
    font-size: 1.5vw;
  }

  .copy input{
    font-size: 1.2vw;
    width: 32vw;
    padding-top: 3.8vw;
  }

  .copy img{
    width: 4vw;
    transform:translate(0%, 0vw);
  }

  .social-conteiner h4{
    color: #fff;
    font-size: 2vw;
    font-family: 'Montserrat';
    font-weight: 400;
    padding-top: 3vw;
    padding-bottom: 4vw;
    padding-left: 13vw;
  }

  .social ul {
    padding-top: 2vw;
  }
  
  
}

@media  (max-width: 321px){
  .airdrop-content img{
    margin-right: 0;
  }
}